import { render, staticRenderFns } from "./CollectionsPage.vue?vue&type=template&id=7a7b2505&scoped=true&"
import script from "./CollectionsPage.vue?vue&type=script&lang=ts&"
export * from "./CollectionsPage.vue?vue&type=script&lang=ts&"
import style0 from "./CollectionsPage.vue?vue&type=style&index=0&id=7a7b2505&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a7b2505",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VContainer,VIcon,VProgressCircular})
