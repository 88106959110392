import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";
import UploaderHome from "@/views/UploaderHome.vue";
import ManageUpload from "@/views/ManageUpload.vue";
import ProfilePage from "@/views/ProfilePage.vue";
import SalesPage from "@/views/SalesPage.vue";
import CatalogPage from "@/views/CatalogPage.vue";
import DraftsPage from "@/views/DraftsPage.vue";
import EditAccountPage from "@/views/EditAccountPage.vue";
import EditProfilePage from "@/views/EditProfilePage.vue";
import SignupPage from "@/views/SignupPage.vue";
import CollectionsPage from "@/views/CollectionsPage.vue";
import MobileModifyCollectionsPage from "@/views/MobileModifyCollectionsPage.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/profile",
    },
    {
      path: "/profile",
      name: "ProfilePage",
      component: ProfilePage,
    },
    {
      path: "/profile/edit-account",
      name: "EditAccountPage",
      component: EditAccountPage,
    },
    {
      path: "/profile/edit-profile",
      name: "EditProfilePage",
      component: EditProfilePage,
    },
    {
      path: "/sales",
      name: "SalesPage",
      component: SalesPage,
    },
    {
      path: "/collections",
      name: "CollectionsPage",
      component: CollectionsPage,
    },
    {
      path: "/collection/:vtexId",
      name: "MobileModifyCollectionsPage",
      component: MobileModifyCollectionsPage,
    },
    {
      path: "/catalogs",
      name: "CatalogPage",
      component: CatalogPage,
    },
    {
      path: "/upload/drafts",
      name: "DraftsPage",
      component: DraftsPage,
    },
    {
      path: "/upload",
      name: "UploaderHome",
      component: UploaderHome,
    },
    {
      path: "/upload/:uploadToken",
      name: "ManageUpload",
      component: ManageUpload,
      props: true,
    },
    {
      path: "/upload/:uploadToken/:step",
      name: "ManageUploadStep",
      component: ManageUpload,
      props: true,
    },
    {
      path: "/signup/:step",
      name: "Signup",
      component: SignupPage,
      props: true,
    },
  ],
});
